import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'
import DocumentPagesLayout from '../components/DocumentPagesLayout'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'

/**
 * Template for page with linked documents
 *
 * @param {object} data 
 * @param {object} pageContext 
 *
 * @returns {JSX Element}
 */
export default function docsLinkTemplate({ data, pageContext }) {


  const { wpPage: { title, translations, language,
    legal_documents: { mainTitle, documentRepeater } },
    allWpMenu,
    allWp: { nodes: [optionsPage] } } = data

  return (
    <>
      <Seo title={title} lang={language.locale} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <DocumentPagesLayout title={mainTitle} docRepeater={documentRepeater} sideBannersData={optionsPage} />
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}

docsLinkTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}


export const docPageQuery = graphql`
  query docPageById($id: String) {
    wpPage(id: {eq: $id}) {
      translations {
        uri
      }
      language {
        locale
      }
      title
      legal_documents {
        mainTitle
        documentRepeater {
          docTitle
          documentRepeater {
            docLink
            docTitle
            pdfOrWord
          }
          fieldGroupName
          subtitle
          title
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
        ...getOptionsPageSideBannerData
      }
    }
  }
`